import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { EMDASH_UNICODE } from 'Constants/characterCodes';
import { NUMERIC_FIELD_LIST } from 'Components/DataGrid/utils';

import { numericColumnStyles } from 'styles';

class EmptyCellRenderer extends Component {
  generateGridCellLabel = () => {
    const emptyCellLabel = this.props.t('transactions.label.noValue');
    this.props.eGridCell.setAttribute('aria-label', emptyCellLabel);
  };

  render() {
    const {
      value,
      colDef: { field }
    } = this.props;

    !value && this.generateGridCellLabel();

    return (
      <div
        tabIndex="-1"
        onFocus={() => !value && this.generateGridCellLabel()}
        style={NUMERIC_FIELD_LIST.includes(field) ? numericColumnStyles : { whiteSpace: 'pre' }}
      >
        {value ? value : EMDASH_UNICODE}
      </div>
    );
  }
}

export default withTranslation()(EmptyCellRenderer);
